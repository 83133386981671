"use client";

import { Loading } from "components";
//import { useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import Hotjar from "@hotjar/browser";
import { helper } from "utils";

const Local = createContext();

const StoreProvider = ({ children, config, events }) => {
  const searchParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );

  useEffect(() => {
    if (searchParams.size) {
      const query = {};

      searchParams.forEach((value, key) => {
        query[key] = value;
      });

      helper.setToStorage("bup-query", query);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      process.env.GOOGLE_TAG_MANAGER_ID
    ) {
      TagManager.initialize({
        auth: process.env.GOOGLE_TAG_MANAGER_AUTH,
        gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
        preview: process.env.GOOGLE_TAG_MANAGER_PREVIEW,
        events: {
          sendUserInfo: "userInfo",
        },
      });
    }
    if (process.env.NODE_ENV === "production" && process.env.HOTJAR_SITE_ID) {
      Hotjar.init(process.env.HOTJAR_SITE_ID, process.env.HOTJAR_VERSION);
    }
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(typeof window === "undefined");
  }, []);

  if (loading) return <Loading />;

  return (
    <Local.Provider value={{ config, events, loading }}>
      {children}
    </Local.Provider>
  );
};

const useLocal = () => useContext(Local);

export { StoreProvider, useLocal };
